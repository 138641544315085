import './App.css';
import Login from './login'
import Probes from './probes'
import { CookiesProvider } from 'react-cookie';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import { ConstProvider } from './context/constants.js'


function App() {
  return (
    <CookiesProvider>
      <ConstProvider>
        <Router>
          <Switch>
            <Route exact path = "/" component ={Login} />
            <Route exact path = "/main" component = {Probes} />
          </Switch>
        </Router>
      </ConstProvider>
    </CookiesProvider>
  );
}


export default App;
