/* eslint-disable */

import react, { useState } from 'react';

import { withCookies } from 'react-cookie';
import { withRouter } from 'react-router';

import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';

import { Button, Grid, Paper, TextField, Typography } from '@material-ui/core';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

import LockOpenRoundedIcon from '@material-ui/icons/LockOpenRounded';

import { useConstContext } from './context/constants';



const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        height: 270,
        width: 300,
    },
}))


/**
 * Login screen.
 */
function Login(props) {

    const constants = useConstContext()
    const classes = useStyles();
    
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [errorDlg, setErrorDlg] = useState(false);

    // Query token.
    let token = props.cookies.get('probes-token');
    if (token != undefined) {
        
        console.log("Login screen had been skipped.")
        
        // Check token validity.
        
        // Move page.
        props.history.push('/main')
    }

    const loginStart = () => {
        console.log(`Login start. (Username: ${username} Password: ${password})`);

        let form_data = new FormData();
        form_data.append('username', username);
        form_data.append('password', password);
        
        const post_url = constants.API_REQUEST_TOKEN;
        axios.defaults.baseURL = constants.POST_BASE_URL;

        axios.post(post_url, form_data, {
            headers: {
                'Content-Type': 'application/json'
            },
        })
        .then( res => {
            props.cookies.set('probes-token', res.data.token);
            props.history.push('/main')
        })
        .catch( error => {
            setErrorDlg(true);
        });
    };
    
    const onClose = () => {
        setErrorDlg(false);
    };

    return (
        <Grid container direction="column" justifyContent="center" alignItems="center" spacing={0}>
            <Paper>
                <Grid container direction="row">

                    <Grid item className={classes.paper}>
                    </ Grid>
        
                    <Grid item className={classes.paper}>
                        <h3>Probes</h3>
                        <form noValidate autoComplete="off">
                            <TextField id="username" label="Name" margin="dense"
                                       onChange={(e) => setUsername(e.target.value)} />
                            <TextField id="password" label="Password" margin="dense" type="password"
                                       onChange={(e) => setPassword(e.target.value)} />
                        </form>
                        <div className="form-element right-placement" style={{marginTop: '30px'}}>
                            <Button variant="contained" color="primary" type="submit" margin="normal"
                                    onClick={loginStart}>
                                <LockOpenRoundedIcon />&nbsp;ログイン
                            </Button>
                        </div>
                    </ Grid>

                </ Grid>
            </ Paper>
            <ErrorDialog message={"ログイン処理に失敗しました。<br />ユーザー名とパスワードをご確認ください。"}
                         show={errorDlg} onClose={onClose} />
        </ Grid>
    );
}


/**
 * Error dialog
 * @param {show, message} props 
 * @returns 
 */
function ErrorDialog(props) {
    
    return(
        <Dialog onClose={props.handleClose}
                aria-labelledby="simple-dialog-title"
                aria-describedby="alert-dialog-description"
                open={props.show}
                onClose={props.onClose}>

            <DialogTitle id="simple-dialog-title">エラー</DialogTitle>

            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <span dangerouslySetInnerHTML={{__html: props.message}} />
                </DialogContentText>
            </DialogContent>
        </Dialog>
    );
}


export default withRouter(withCookies(Login));
