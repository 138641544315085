import { createContext, useContext } from 'react';


const ConstContext = createContext();

export function useConstContext() {
    return useContext(ConstContext);
}

export function ConstProvider({ children }) {
    const data = {

        // POST_BASE_URL: 'http://127.0.0.1:8000',
        POST_BASE_URL: 'https://www.newron-kansai.net/probes',
        
        // APIs.
        API_REQUEST_TOKEN: '/accounts/api-token-auth/',
    };
    
    return (
        <ConstContext.Provider value={data}>
            {children}
        </ConstContext.Provider>
    );
}